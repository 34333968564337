import React, { useEffect, useState } from "react";
import { Upload, message, Progress } from "antd";
import { InboxOutlined } from "@ant-design/icons";
import { common_axios } from "../../utils/AxiosSettings";
import axios from "axios";
import DocumentViewer from "./ShowDocHeaderFile";

const { Dragger } = Upload;

const UploadComponent = ({
    Files,
    maxFiles,
    model,
    companyFk,
    id,
    allowedFileTypes,
    refresh,
    modelMethod, //for knowing if it's a new model or not
    onModelCreated,
    setOnModalCreated,
    onUploadComplete,
    externalFileList,
    onExternalFileListChange,
    document_delete_on,
    document_download_on,
    disableUpload,
    justUpload,
    scrollable,
    setDisableLink,
    "data-testid": dataTestId,
}) => {
    const [uploadProgress, setUploadProgress] = useState(0);
    const [fileList, setFileList] = useState(externalFileList || []);

    const handleUpload = async (file, url, fileid) => {
        let filesProcessed = 0;
        try {
            await axios.put(url, file.originFileObj, {
                onUploadProgress: (progressEvent) => {
                    const singleFileProgress = Math.round(
                        (progressEvent.loaded * 100) / progressEvent.total
                    );
                    const overallProgress = Math.round(
                        ((filesProcessed + singleFileProgress / 100) * 100) /
                            fileList.length
                    );

                    setUploadProgress(overallProgress);
                },
            });
            filesProcessed += 1;

            await common_axios.patch(
                `${process.env.REACT_APP_BASE_URL_API}/api/accounts/upload/`,
                { file_id: fileid, model: model, model_id: id }
            );

            message.success(`File ${file.name} uploaded successfully!`);
        } catch (error) {
            message.error(`Error uploading file ${file.name}`);
        }
    };

    useEffect(() => {
        if (modelMethod === "new") {
            return; // do nothing if it's a new model
        }
        if (
            fileList?.length != 0 &&
            Files.length + fileList?.length <= maxFiles
        ) {
            generatePresignedUrls();
        }
    }, [fileList?.length]);

    useEffect(() => {
        setFileList(externalFileList || []);
    }, [externalFileList]);

    useEffect(() => {
        if (
            onModelCreated &&
            fileList?.length != 0 &&
            Files.length + fileList?.length <= maxFiles
        ) {
            generatePresignedUrls();
            setOnModalCreated(false);
        }
    }, [onModelCreated]);

    const generatePresignedUrls = async () => {
        try {
            if (fileList?.length === 0) {
                console.log("No files selected for upload");
                return;
            }

            if (fileList?.length > maxFiles) {
                message.warning(
                    `Only ${maxFiles} file(s) allowed to be uploaded`
                );
                return;
            }

            const urls = [];
            for (let i = 0; i < fileList?.length; i++) {
                const file = fileList[i];
                const fileName = file.name;
                const fileExtension = fileName.split(".").pop();

                if (!allowedFileTypes.includes(fileExtension)) {
                    message.error(
                        `Invalid file type. Only the following file types are allowed: ${allowedFileTypes.join(
                            ", "
                        )}`
                    );
                    return;
                }

                const response = await common_axios.post(
                    `${process.env.REACT_APP_BASE_URL_API}/api/accounts/upload/`,
                    {
                        file_name: fileName,
                        file_type: fileExtension,
                        model,
                        company_fk: companyFk,
                        id,
                    }
                );

                const url = response.data.url;
                const fileid = response.data.id;
                urls.push(url);

                await handleUpload(file, url, fileid);
            }

            // All files uploaded successfully
            message.success("All files uploaded successfully!");
            if (onUploadComplete) {
                onUploadComplete();
            }
            if (refresh) {
                refresh();
            }
            setFileList([]);
        } catch (error) {
            console.error("Error generating presigned URLs:", error);
            message.error("Error generating presigned URLs");
        }
    };

    const handleBeforeUpload = (file) => {
        const fileExtension = file.name.split(".").pop();
        if (!allowedFileTypes.includes(fileExtension)) {
            message.error(
                "Invalid file type. Only the following file types are allowed: " +
                    allowedFileTypes.join(", ")
            );
            return false;
        }
        setDisableLink(true);
        return false;
    };
    const handleRemove = (file) => {
        setDisableLink && setDisableLink(false);
    };

    const handleFileListChange = (info) => {
        let newFileList = [...info.fileList];

        // Limit the fileList length to the maximum allowed files
        if (newFileList?.length > maxFiles) {
            newFileList = newFileList.slice(0, maxFiles);
        }
        setFileList(newFileList);

        // Notify the parent component
        if (onExternalFileListChange) {
            onExternalFileListChange(newFileList);
        }

        const uploadedFiles = newFileList.filter(
            (file) => file.status === "done"
        );
        setUploadProgress((uploadedFiles?.length * 100) / newFileList?.length);
    };

    return (
        <div data-testid={dataTestId}>
            {Files && Files.length !== 0 && !justUpload ? (
                <DocumentViewer
                    document_delete_on={document_delete_on}
                    document_download_on={document_download_on}
                    documentIds={Files}
                    model={model}
                    model_id={id}
                    refresh={refresh}
                    scrollable={scrollable}
                />
            ) : (
                <div>No document available</div>
            )}
            {uploadProgress > 0 && uploadProgress < 100 && (
                <Progress
                    percent={uploadProgress}
                    status="active"
                    strokeColor={{
                        "0%": "#108ee9",
                        "100%": "#87d068",
                    }}
                />
            )}
            {Files &&
                (Files.length < maxFiles || justUpload) &&
                !disableUpload && (
                    <Dragger
                        multiple
                        fileList={fileList}
                        progress={{
                            strokeColor: {
                                "0%": "#108ee9",
                                "100%": "#87d068",
                            },
                            strokeWidth: 3,
                            format: (percent) =>
                                percent && `${parseFloat(percent.toFixed(2))}%`,
                        }}
                        accept={allowedFileTypes.map(
                            (fileType) => `.${fileType}`
                        )}
                        beforeUpload={handleBeforeUpload}
                        onRemove={handleRemove}
                        onChange={handleFileListChange}>
                        <p className="ant-upload-drag-icon">
                            <InboxOutlined />
                        </p>
                        <p className="ant-upload-text">
                            Click or drag file to this area to upload
                        </p>
                    </Dragger>
                )}
        </div>
    );
};

export default UploadComponent;
