/* Login Component with token request by Username and password and based on token redirects to home component */

import { useState } from "react";
import styles from "./Login.module.css";

import { useNavigate } from "react-router-dom";
import React from "react";
import SimpleImageSlider from "react-simple-image-slider";

import { useRecoilState, useResetRecoilState } from "recoil";
import { userState } from "../../RecoilState/userState";
import { loginUser } from "../../Adapters/userCalls";
import { message } from "antd";
import { useEffect } from "react";
import { tokenState } from "../../RecoilState/tokenState";
import { Cookies, useCookies } from "react-cookie";
import ResetLink from "./ResetLink/ResetLink.jsx";
import { common_axios, setNavigateCallback } from "../../utils/AxiosSettings";
import { availableUserRoleStates } from "../../RecoilState/GlobalUserRoleStates/availableUserRoleStates";
import { currentSelectedRoleState } from "../../RecoilState/GlobalUserRoleStates/currentSelectedRoleState";
import RoleSelectModal from "./RoleSelectModal/index.jsx";
import { dataAtLoginState } from "../../RecoilState/dataAtLoginState";
import { userMetricState } from "../../RecoilState/userMerticState";
import { isEmpty } from "lodash";

const imagesArray = [
    {
        url: `/assets/images/LoginPage/top2.png`,
    },
    {
        url: `/assets/images/LoginPage/top3.png`,
    },
    {
        url: `/assets/images/LoginPage/top4.png`,
    },
];

const wrongUsernamePasswordImages = [
    {
        url: `/assets/images/LoginPage/password1.png`,
    },
    {
        url: `/assets/images/LoginPage/password2.png`,
    },
];

export const setAvailableRolesFunction = (resData, setAvailableUserRoles) => {
    if (resData && resData.roles) {
        const allRoles = [];
        // Iterate through the roles and extract the required fields
        for (const roleName in resData.roles) {
            if (resData.roles.hasOwnProperty(roleName)) {
                const roleData = resData.roles[roleName];
                allRoles.push({
                    user_permissions: roleData.user_permissions,
                    role: roleData.role,
                    id: roleData.id,
                    factory_fk: roleData.factory_fk,
                    group_permissions: roleData.group_permissions,
                    Location: roleData.Location,
                    Code: roleData.Code,
                    region: roleData.region,
                    region_fk: roleData.region_fk,
                    timezone: roleData.timezones?.default_timezone,
                });
            }
        }
        setAvailableUserRoles(allRoles);
        localStorage.setItem("available-role-cookie", JSON.stringify(allRoles));
        localStorage.setItem("flag", true);
    }
};

export default function Login(props) {
    const cookies = new Cookies();
    const navigate = useNavigate();
    const [username, setusername] = useState("");
    const [password, setpassword] = useState("");
    const [passwordVisiblity, setpasswordVisiblity] = useState(false);
    const [PwdError, setPwdError] = useState(false);
    const [usrError, setusrError] = useState(false);
    const [imagesDefault, setimagesDefault] = useState(true);
    const [token, setToken] = useRecoilState(tokenState);
    const [roleCookie, setRoleCookie, removeRoleCookie] = useCookies([
        "role-token-cookie",
    ]);
    const [userCookie, setUserCookie, removeUserCookie] = useCookies([
        "user-token-cookie",
    ]);
    const [ResetPasswordNavigate, setResetPasswordNavigate] = useState(false);
    const [availableUserRoles, setAvailableUserRoles] = useRecoilState(
        availableUserRoleStates
    );
    const [availableroleIsOpen, setAvailableroleIsOpen] = useState(false);
    const [currentSelectedRole, setCurrentSelectedRole] = useRecoilState(
        currentSelectedRoleState
    );
    const [dataAtLogin, setDataAtLogin] = useRecoilState(dataAtLoginState);
    const nav = useNavigate();
    const resetUser = useResetRecoilState(userState);
    const resetToken = useResetRecoilState(tokenState);
    const [userMetricData, setUserMetricData] = useRecoilState(userMetricState);
    const [loading, setLoading] = useState(false);
    //useEffect to resolve error when user session expires and doesn't have userMetricState in local storage
    useEffect(() => {
        if (
            !localStorage.getItem("userMetricState") ||
            !cookies.get("role-token-cookie")
        ) {
            resetUser();
            resetToken();
            removeUserCookie("user-token-cookie", { path: "/" });
            removeRoleCookie("role-token-cookie", { path: "/" });
            localStorage.removeItem("current-selected-role");
            localStorage.removeItem("available-role-cookie");
            localStorage.removeItem("userMetricState");
            localStorage.removeItem("flag");
            setCurrentSelectedRole("");
        }
    }, []);

    useEffect(() => {
        // Define the callback function for navigation
        const navigateTo = (path) => {
            nav(path);
        };

        // Set the callback for navigation
        setNavigateCallback(navigateTo);
    }, []);

    useEffect(() => {
        setAvailableUserRoles(availableUserRoleStates); // Update the localValue when the Recoil state changes
        // Perform any other action you want to take with the updated value here
    }, [availableUserRoleStates]);

    const handleSubmit = (e) => {
        e.preventDefault();
        if (username === "" || password == "") {
            alert("Please check username and password to proceed");
        } else if (!validateUsername(username)) {
            setusrError(true);
            setPwdError(false);
        }
        setLoading(true);
        const getToken = loginUser(username, password);

        getToken
            .then((res) => {
                if (res.status === 200) {
                    if (isEmpty(res.data.roles)) {
                        message.error(
                            "Account has no active roles, please create one to login"
                        );
                        setLoading(false);
                        return;
                    }
                    message.success("Login Successful");
                    cookies.set("token", res.data.tokens, {
                        path: "/",
                    });
                    common_axios.defaults.headers.common[
                        "Authorization"
                    ] = `Bearer ${res.data.tokens?.access}`;
                    setUserCookie("user-token-cookie", res.data, { path: "/" });

                    const jwtPayload = JSON.parse(
                        window.atob(res.data?.tokens?.refresh?.split(".")[1])
                    );

                    setToken({ ...res.data.tokens, expires: jwtPayload?.exp });
                    if (res.data && res.data.roles) {
                        setAvailableRolesFunction(
                            res.data,
                            setAvailableUserRoles
                        );
                    }
                    getToken.then((res) => {
                        setDataAtLogin(res?.data?.atLogin);
                        if (
                            res?.data?.atLogin.render_reset_password === "false"
                        ) {
                            setAvailableroleIsOpen(true);
                        } else {
                            setAvailableroleIsOpen(true);
                        }
                    });
                    setLoading(false);
                    setUserMetricData((prevUserMetricState) => {
                        const updatedUserMetricState = {
                            ...prevUserMetricState,
                            userMetrics: {
                                ...prevUserMetricState?.userMetrics,
                                email: res.data?.userData?.email,
                                userID: res.data?.userData?.id,
                                loginTimestamp: new Date().toUTCString(),
                                logoutTimestamp: new Date(
                                    jwtPayload.exp * 1000
                                ).toUTCString(),
                            },
                        };
                        // Set the updated user metric state in local storage
                        localStorage.setItem(
                            "userMetricState",
                            JSON.stringify(updatedUserMetricState)
                        );

                        return updatedUserMetricState;
                    });
                }
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.status == "401") {
                        message.error("Login Failed, Check credentials");
                        setimagesDefault(false);
                        // navigate("/error");
                    }
                    if (error.response.status == "403") {
                        message.error(error.response.data.error);
                        setimagesDefault(false);
                        // navigate("/error");
                    }
                }
                setLoading(false);
            });
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        const trimmedValue = value.trim(); // Trim the whitespace from the input value

        if (name === "username") {
            setusername(trimmedValue);
        } else {
            setpassword(trimmedValue);
        }
    };

    const validateUsername = (value) => {
        if (value.length < 8) {
            return false;
        } else {
            return true;
        }
    };

    if (availableUserRoles && availableUserRoles.length > 0) {
        return (
            <RoleSelectModal
                isOpen={availableroleIsOpen}
                roles={availableUserRoles}
                dataAtLogin={dataAtLogin}
            />
        );
    } else {
        return (
            <div className={styles.loginContainer}>
                <div className={styles.leftLogin}>
                    <form onSubmit={handleSubmit} className={styles.loginMain}>
                        <div className={styles.mainHeadingWrapper}>
                            <h2>Worker wellbeing is Good Business</h2>
                        </div>
                        <p
                            className={styles.SignIn}
                            onClick={() => {
                                setResetPasswordNavigate(false);
                            }}>
                            Sign In
                        </p>
                        {ResetPasswordNavigate ? (
                            <ResetLink />
                        ) : (
                            <div className={styles.loginDetails}>
                                <div>
                                    <label>
                                        Email
                                        <div
                                            id={
                                                usrError ? styles.usrError : ""
                                            }>
                                            <input
                                                required
                                                type="text"
                                                name="username"
                                                value={username}
                                                placeholder="Enter Email"
                                                onChange={handleChange}
                                            />{" "}
                                            <span
                                                onClick={() => {
                                                    setusername("");
                                                }}>
                                                <img src="/assets/images/LoginPage/cross.svg" />
                                            </span>
                                        </div>
                                    </label>
                                </div>
                                <div>
                                    <label>
                                        Password
                                        <div
                                            id={
                                                PwdError
                                                    ? styles.passwordError
                                                    : ""
                                            }>
                                            <input
                                                required
                                                type={
                                                    passwordVisiblity
                                                        ? "text"
                                                        : "password"
                                                }
                                                placeholder="Enter Password"
                                                name="password"
                                                onChange={handleChange}
                                            />
                                            <span
                                                onClick={() => {
                                                    setpasswordVisiblity(
                                                        !passwordVisiblity
                                                    );
                                                }}>
                                                {" "}
                                                <img src="/assets/images/LoginPage/eye.svg" />
                                            </span>
                                        </div>
                                    </label>
                                    <div className={styles.forgetPassword}>
                                        <span
                                            onClick={() =>
                                                navigate("/resetLink")
                                            }>
                                            Forgot Password?
                                        </span>
                                    </div>
                                </div>
                            </div>
                        )}
                        {!ResetPasswordNavigate && (
                            <>
                                <button
                                    disabled={loading}
                                    // className={loading && `${styles.loading}`}
                                    type="submit">
                                    LOGIN
                                </button>
                            </>
                        )}
                    </form>
                </div>
                <div className={styles.rightLogin}>
                    {imagesDefault ? (
                        <SimpleImageSlider
                            width={596}
                            height={604}
                            images={imagesArray}
                            showBullets={true}
                            showNavs={false}
                            bgColor="transparent"
                            autoPlay={true}
                            autoPlayDelay={3.0}
                            slideDuration={1.0}
                        />
                    ) : (
                        <img
                            width={596}
                            height={604}
                            src={wrongUsernamePasswordImages[1].url}
                        />
                    )}
                </div>
            </div>
        );
    }
}
