import React, { useState, useEffect } from "react";
import { Modal, Table, Input, Button } from "antd";
import {
    getSentDataByID,
    getSentMessagesData,
} from "../../../Adapters/BroadcastmessageCalls";
import _ from "lodash";
import DateTimeFormatter from "../../../components/Utils/DateTimeFormatter";
import DraftModal from "../DraftMsg/DraftModal";
const SentMessage = ({ mainTabKey }) => {
    const [sentTableData, setSentTableData] = useState();
    const [sentModalData, setSentModalData] = useState();
    const [open, setOpen] = useState(false);
    const [draftID, setDraftID] = useState();
    const [confirmLoading, setConfirmLoading] = useState(false);
    // const [sentMessageFlag, setSentMessageFlag] = useState(false);
    const [modalText, setModalText] = useState("Content of the modal");

    const [selectedKeys, setSelectedKeys] = useState([]);

    const searchDropdownVisible = (visible) => {
        if (visible) {
            setSelectedKeys([]);
        } else {
            setSelectedKeys([]);
        }
    };

    const searchDropdown = ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
    }) => {
        return (
            <div style={{ padding: 8 }}>
                <Input
                    placeholder="Search"
                    value={selectedKeys[0]}
                    onChange={(e) =>
                        setSelectedKeys(e.target.value ? [e.target.value] : [])
                    }
                    onPressEnter={confirm}
                    style={{
                        width: 188,
                        marginBottom: 8,
                        display: "block",
                    }}
                />
                <Button
                    type="primary"
                    onClick={confirm}
                    size="small"
                    style={{ width: 90, marginRight: 8 }}>
                    Filter
                </Button>
                <Button
                    onClick={() => {
                        clearFilters();
                        confirm();
                    }}
                    size="small"
                    style={{ width: 90 }}>
                    Reset
                </Button>
            </div>
        );
    };
    const getSentDataHandler = async () => {
        try {
            const res = await getSentMessagesData();
            setSentTableData(res?.data.message_body);
        } catch (error) {}
    };
    // const editDraftHandler = async () => {
    //     const res = await editDraft(draftID);

    //     setOpen(false);
    //     setShowEditTabs(true);
    //     setdraftFlag(true);
    //     // setPreFilledDraftData(res.data.message_body);
    // };

    const rowClickHandler = async (record) => {
        setDraftID(record.id);
        try {
            const res = await getSentDataByID(record.id);

            setSentModalData(res?.data.message_body);
        } catch (err) {
            console.log(err.message);
        }
        showModal();
    };

    const showModal = () => {
        setOpen(true);
    };

    const handleOk = () => {
        setModalText("The modal will be closed after two seconds");
        setConfirmLoading(true);
        setTimeout(() => {
            setOpen(false);
            setConfirmLoading(false);
        }, 2000);
    };

    const handleCancel = () => {
        setOpen(false);
    };

    useEffect(() => {
        if (mainTabKey === "4") {
            getSentDataHandler();
        }
        // setSentMessageFlag(true);
    }, [mainTabKey]);

    const filterData = (data, formatter) =>
        _.uniqBy(data, formatter).map((item) => ({
            text: formatter(item),
            value: formatter(item),
        }));

    const columns = [
        {
            title: "Date and time",
            dataIndex: "lastModified",
            key: "lastModified",
            filterMode: "tree",
            filterSearch: true,

            render: (text, record) => (
                <DateTimeFormatter
                    dateTimeString={`${record.Date} ${record.Time}`}
                />
            ),
            sorter: (a, b) =>
                new Date(`${a.Date} ${a.Time}`).getTime() -
                new Date(`${b.Date} ${b.Time}`).getTime(),
            // filters: filterData(sentTableData, (item) => (
            //     <DateTimeFormatter dateTimeString={item.lastModified} />
            // )),
            // onFilter: (value, record) => record.lastModified.includes(value),
            width: "15%",
        },
        {
            title: "Total Count",
            dataIndex: "TotalCount",
            key: "TotalCount",
            filterMode: "tree",
            filterSearch: true,
            sorter: (a, b) => a.TotalCount - b.TotalCount,
            // filters: filterData(sentTableData, (item) => item.TotalCount),
            // onFilter: (value, record) => record.TotalCount.includes(value),
            width: "10%",
        },
        {
            title: "Languages",
            dataIndex: "languages",
            key: "languages",
            filterMode: "menu",
            sorter: (a, b) => a.languages - b.languages,
            filters: filterData(sentTableData, (item) => item.languages),
            onFilter: (value, record) => record.languages.includes(value),
            width: "15%",
        },
        {
            title: "Template",
            dataIndex: "templateTitle",
            key: "templateTitle",
            filterMode: "menu",
            sorter: (a, b) => a.templateTitle - b.templateTitle,
            filters: filterData(sentTableData, (item) => item.templateTitle),
            onFilter: (value, record) => record.templateTitle.includes(value),
            width: "15%",
        },
        {
            title: "Sent By",
            dataIndex: "createdBy",
            key: "createdBy",
            filterMode: "tree",
            filterSearch: true,
            filters: filterData(sentTableData, (item) => item.createdBy),
            onFilter: (value, record) => {
                const searchValue = value.toLowerCase().trim();
                const createdBy = record.createdBy
                    .toString()
                    .toLowerCase()
                    .trim();
                return createdBy.indexOf(searchValue) !== -1;
            },
            filterDropdown: searchDropdown,
            onFilterDropdownVisibleChange: searchDropdownVisible,
            width: "15%",
        },
        {
            title: "Message",
            dataIndex: "Message",
            key: "Message",
            width: "40%",
        },
    ];

    const pagination = {
        pageSize: 5,
    };

    return (
        <div style={{ height: "100%" }}>
            <Table
                rowKey="id"
                scroll={{ y: 380 }}
                columns={columns}
                dataSource={sentTableData}
                pagination={pagination}
                onRow={(record) => ({
                    onClick: () => rowClickHandler(record),
                })}
            />

            <Modal
                width={900}
                centered
                title="Sent Details"
                open={open}
                onOk={handleOk}
                confirmLoading={confirmLoading}
                onCancel={handleCancel}
                destroyOnClose={true}
                footer={false}>
                <DraftModal
                    draftModalData={sentModalData}
                    messageID={draftID}
                    mainTabKey={mainTabKey}
                    // sentMessageFlag={sentMessageFlag}
                    // editDraftHandler={editDraftHandler}
                />
            </Modal>
        </div>
    );
};

export default SentMessage;
