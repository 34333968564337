import React from "react";
import { Modal } from "antd";
import styles from "./ActionModal.module.css";

const ActionModal = ({
    isOpen,
    onCancel,
    title,
    content,
    actions,
    width = 400,
}) => {
    return (
        <Modal
            title={<div className={styles.modalHeader}>{title}</div>}
            open={isOpen}
            onCancel={onCancel}
            footer={null}
            width={width}>
            <div className={styles.modalContent}>
                <p>{content}</p>
                <ol>
                    {actions.map((action, index) => (
                        <li key={index}>
                            {action.text}
                            <button
                                className={styles.confimButton}
                                onClick={action.handler}>
                                {action.buttonText}
                            </button>
                        </li>
                    ))}
                </ol>
            </div>
        </Modal>
    );
};

export default ActionModal;
