import React, { useState, useEffect } from "react";
import { useRecoilState } from "recoil";
import { Button, Modal, Table, Tooltip, Input } from "antd";
import { EditOutlined } from "@ant-design/icons";
import { editDraft } from "../../../Adapters/BroadcastmessageCalls";
import {
    getDraftDataByID,
    getDraftOfBroadcastMessage,
} from "../../../Adapters/BroadcastmessageCalls";
import DraftModal from "./DraftModal";
import NewMessage from "../NewMsg";
import _ from "lodash";
import DateTimeFormatter from "../../../components/Utils/DateTimeFormatter";
const DraftMessage = ({ mainTabKey }) => {
    const [draftTableData, setDraftTableData] = useState();
    const [draftModalData, setDraftModalData] = useState();
    const [draftID, setDraftID] = useState();

    const [showEditTabs, setShowEditTabs] = useState(false);
    const [draftFlag, setdraftFlag] = useState(false);
    const [preFilledDraftData, setPreFilledDraftData] = useState();
    const [selectedKeys, setSelectedKeys] = useState([]);

    const searchDropdownVisible = (visible) => {
        if (visible) {
            setSelectedKeys([]);
        } else {
            setSelectedKeys([]);
        }
    };

    const searchDropdown = ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
    }) => {
        return (
            <div style={{ padding: 8 }}>
                <Input
                    placeholder="Search"
                    value={selectedKeys[0]}
                    onChange={(e) =>
                        setSelectedKeys(e.target.value ? [e.target.value] : [])
                    }
                    onPressEnter={confirm}
                    style={{
                        width: 188,
                        marginBottom: 8,
                        display: "block",
                    }}
                />
                <Button
                    type="primary"
                    onClick={confirm}
                    size="small"
                    style={{ width: 90, marginRight: 8 }}>
                    Filter
                </Button>
                <Button
                    onClick={() => {
                        clearFilters();
                        confirm();
                    }}
                    size="small"
                    style={{ width: 90 }}>
                    Reset
                </Button>
            </div>
        );
    };

    const getDraftDataHandler = async () => {
        const res = await getDraftOfBroadcastMessage();

        setDraftTableData(res?.data.message_body);
    };

    const editDraftHandler = async () => {
        const res = await editDraft(draftID);

        setOpen(false);
        setShowEditTabs(true);
        setdraftFlag(true);
        setPreFilledDraftData(res.data.message_body);
    };

    useEffect(() => {
        if (mainTabKey === "3") getDraftDataHandler();
    }, [mainTabKey]);

    const rowClickHandler = async (record) => {
        showModal();
        setDraftID(record.id);
        const res = await getDraftDataByID(record.id);

        setDraftModalData(res?.data.message_body);
    };

    const [open, setOpen] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [modalText, setModalText] = useState("Content of the modal");

    const showModal = () => {
        setOpen(true);
    };

    const handleOk = () => {
        setModalText("The modal will be closed after two seconds");
        setConfirmLoading(true);
        setTimeout(() => {
            setOpen(false);
            setConfirmLoading(false);
        }, 2000);
    };

    const handleCancel = () => {
        setOpen(false);
    };

    const filterData = (data, formatter) =>
        _.uniqBy(data, formatter).map((item) => ({
            text: formatter(item),
            value: formatter(item),
        }));

    const columns = [
        {
            title: "Last Modified",
            dataIndex: "lastModified",
            key: "lastModified",
            filterMode: "tree",
            filterSearch: true,

            render: (_, record, index) => {
                if (hoveredRow === index && record.editAccess === "Yes") {
                    return (
                        <>
                            <DateTimeFormatter
                                dateTimeString={record.lastModified}
                            />
                            <Tooltip title="Edit">
                                <Button
                                    style={{
                                        marginLeft: ".5rem",
                                    }}
                                    type="text"
                                    icon={<EditOutlined />}
                                />
                            </Tooltip>
                        </>
                    );
                }

                return (
                    <DateTimeFormatter dateTimeString={record.lastModified} />
                );
            },
            sorter: (a, b) =>
                new Date(a.lastModified).getTime() -
                new Date(b.lastModified).getTime(),

            width: "17%",
        },
        {
            title: "Created By",
            dataIndex: "createdBy",
            key: "createdBy",
            filterMode: "menu",
            sorter: (a, b) => a.createdBy - b.createdBy,
            filters: filterData(draftTableData, (item) => item.createdBy),
            onFilter: (value, record) => record.createdBy.includes(value),
            width: "13%",
        },
        {
            title: "Languages",
            dataIndex: "languages",
            key: "languages",
            filterMode: "menu",
            sorter: (a, b) => a.languages - b.languages,
            filters: filterData(draftTableData, (item) => item.languages),
            onFilter: (value, record) => record.languages.includes(value),
            width: "15%",
        },
        {
            title: "Template",
            dataIndex: "templateTitle",
            key: "templateTitle",
            filterMode: "tree",
            filterSearch: true,
            sorter: (a, b) => a.templateTitle - b.templateTitle,
            filters: filterData(draftTableData, (item) => item.templateTitle),
            onFilter: (value, record) => {
                const searchValue = value.toLowerCase().trim();
                const templateTitle = record.templateTitle
                    .toString()
                    .toLowerCase()
                    .trim();
                return templateTitle.indexOf(searchValue) !== -1;
            },
            filterDropdown: searchDropdown,
            onFilterDropdownVisibleChange: searchDropdownVisible,
            width: "15%",
        },

        {
            title: "Message",
            dataIndex: "Message",
            key: "Message",
            width: "35%",
        },
    ];

    const onChange = (pagination, filters, sorter, extra) => {
        console.log("params", pagination, filters, sorter, extra);
    };

    const pagination = {
        pageSize: 5,
    };

    const [hoveredRow, setHoveredRow] = useState(null);

    const handleRowMouseEnter = (record, index) => {
        if (record.editAccess === "Yes") {
            setHoveredRow(index);
        }
    };

    const handleRowMouseLeave = () => {
        setHoveredRow(null);
    };

    return (
        <div style={{ height: "100%" }}>
            {showEditTabs ? (
                <NewMessage
                    setShowEditTabs={setShowEditTabs}
                    draftFlag={draftFlag}
                    preFilledDraftData={preFilledDraftData}
                />
            ) : (
                <Table
                    rowKey="id"
                    columns={columns}
                    dataSource={draftTableData}
                    scroll={{ y: 380 }}
                    onChange={onChange}
                    pagination={pagination}
                    onRow={(record, index) => ({
                        onClick: () => rowClickHandler(record),
                        onMouseEnter: () => handleRowMouseEnter(record, index),
                        onMouseLeave: handleRowMouseLeave,
                    })}
                />
            )}

            <Modal
                width={900}
                centered
                title="Draft Details"
                open={open}
                onOk={handleOk}
                confirmLoading={confirmLoading}
                onCancel={handleCancel}
                destroyOnClose={true}
                footer={false}>
                <DraftModal
                    draftModalData={draftModalData}
                    messageID={draftID}
                    editDraftHandler={editDraftHandler}
                    mainTabKey={mainTabKey}
                />
            </Modal>
        </div>
    );
};

export default DraftMessage;
