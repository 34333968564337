import { useState, useEffect } from "react";
import { getCategories } from "../Adapters/CaseReportCalls";

const useCategories = (companyFk) => {
    const [categoriesData, setCategoriesData] = useState([]);
    const [categoryOptions, setCategoryOptions] = useState([]);
    const [subCategoryOptions, setSubCategoryOptions] = useState([]);

    useEffect(() => {
        const fetchCategories = async () => {
            if (companyFk) {
                const res = await getCategories(companyFk);
                const categories = res.data;
                setCategoriesData(categories);
                const uniqueCategories = Array.from(
                    new Set(categories.map((item) => item.category))
                );
                const categoryOptions = uniqueCategories.map((category) => ({
                    label: category,
                    value: category,
                }));
                setCategoryOptions(categoryOptions);
            }
        };

        fetchCategories();
    }, [companyFk]);

    const updateSubCategoryOptions = (selectedCategory) => {
        const filteredData = categoriesData.filter(
            (item) => item.category === selectedCategory
        );

        // Extract unique subcategories with their IDs
        const uniqueSubcategories = [
            ...new Map(
                filteredData.map((item) => [item.sub_category, item.id])
            ).entries(),
        ];

        // Map to options for Select component
        const subCategoryOptions = uniqueSubcategories.map(
            ([subCategory, id]) => ({
                label: subCategory,
                value: id,
            })
        );
        setSubCategoryOptions(subCategoryOptions);
    };

    return {
        categoryOptions,
        subCategoryOptions,
        updateSubCategoryOptions,
    };
};

export default useCategories;
