import React from "react";
import { Row, Col, message, Modal } from "antd";
import { capitalize } from "lodash";
import "../../../../common.css";
import styles from "../NewMsg.module.css";
import { Select } from "antd";
import { useState, useEffect } from "react";
import {
    getMessagePreviewData,
    saveDraftBroadcastMessage,
    saveDraftEditBroadcastMessage,
    sendMessageBroadcast,
    sendMessageEditBroadcast,
} from "../../../../Adapters/BroadcastmessageCalls";
import useEventMetricsUpdater from "../../../../hooks/useEventMetricsUpdater";
import { useNavigate } from "react-router";
const PreviewMessage = ({ previewScreenData, draftID, setActiveTab }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isSkipModalOpen, setSkipIsModalOpen] = useState(false);
    const [modalData, setModalData] = useState({
        eventName: "",
        actionType: "",
    });
    const [previewDataInputs, setPreviewDataInputs] = useState({
        language: "English",
        factory: null,
        subDeptId: null,
        gender: null,
    });
    const [previewData, setPreviewData] = useState("");

    const { updateEventMetrics } = useEventMetricsUpdater();
    const navigate = useNavigate();

    const showModal = (eventName, actionType) => {
        setIsModalOpen(true);
        setModalData({ eventName, actionType });
    };

    const handleOk = () => {
        sendMessageHandler();
        setIsModalOpen(false);
        updateEventMetrics(modalData.eventName, modalData.actionType);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
        setSkipIsModalOpen(false);
    };
    const saveDraftHandler = async () => {
        if (draftID) {
            const res = await saveDraftEditBroadcastMessage({ draftID });
            message.success(res.data.message);
            setTimeout(refreshPage, 1000);
        } else {
            const res = await saveDraftBroadcastMessage();

            message.success(res.data.message);
            setTimeout(refreshPage, 1000);
        }
    };
    function refreshPage() {
        window.location.reload(false);
    }
    const sendMessageHandler = async (action = null) => {
        if (draftID) {
            const res = await sendMessageEditBroadcast({ draftID, action });

            message.success(res.data.message);

            if (res.data?.message_body?.showConfirmation) {
                setSkipIsModalOpen(true);
                setIsModalOpen(false);
            } else {
                setSkipIsModalOpen(false);
            }
            if (!res.data?.message_body?.showConfirmation)
                setTimeout(refreshPage, 1000);
        } else {
            const res = await sendMessageBroadcast({ action });
            message.success(res.data.message);

            if (res.data?.message_body?.showConfirmation) {
                setSkipIsModalOpen(true);
                setIsModalOpen(false);
            } else {
                setSkipIsModalOpen(false);
            }
            if (!res.data?.message_body?.showConfirmation)
                setTimeout(refreshPage, 1000);
        }
    };
    function toPascalCase(str) {
        return str.toLowerCase().replace(/(?:^|\s)\w/g, function (match) {
            return match.toUpperCase();
        });
    }

    const { Option, OptGroup } = Select;

    // const languageValueHandler = (val) => {
    //     setValue(val);
    // };
    // const factoryValueHandler = (val) => {
    //     setSelectedFactoryValues(val);
    // };

    const previewMessageData = async () => {
        if (
            previewDataInputs.language &&
            previewDataInputs.subDeptId &&
            previewDataInputs.factory &&
            previewDataInputs.gender
        ) {
            let queryUrl = new URL(
                `${process.env.REACT_APP_BASE_URL_API}/api/accounts/filtermsgs`
            );
            const departmentParam = JSON.stringify(previewDataInputs.subDeptId);
            queryUrl.searchParams.append("department", departmentParam);
            queryUrl.searchParams.append(
                "language",
                previewDataInputs.language
            );
            queryUrl.searchParams.append("factory", previewDataInputs.factory);
            queryUrl.searchParams.append("gender", previewDataInputs.gender);
            const res = await getMessagePreviewData(queryUrl);
            setPreviewData(res?.data.message_body);
        }
    };
    useEffect(() => {
        previewMessageData();
    }, [
        previewDataInputs.language,
        previewDataInputs.subDeptId,
        previewDataInputs.factory,
        previewDataInputs.gender,
    ]);

    const options = previewScreenData?.subDepartments.reduce((acc, curr) => {
        if (!acc[curr.Department]) {
            acc[curr.Department] = [];
        }
        acc[curr.Department].push(curr);
        return acc;
    }, {});
    const result = [];
    const d = previewScreenData?.subDepartments.forEach((item) => {
        if (!result.includes(item.Department)) {
            result.push(item.Department);
        }
        if (!result.includes(item.SubDepartment)) {
            result.push(item.SubDepartment);
        }
    });

    const handleValueChange = (type) => (value) => {
        setPreviewDataInputs((prevState) => ({
            ...prevState,
            [type]: value,
        }));
    };

    const getSubDeptValue = () => {
        if (previewDataInputs.subDeptId) {
            return capitalize(Object.values(previewDataInputs.subDeptId)[0]);
        }

        return null;
    };

    return (
        <>
            <div
                style={{
                    margin: "0rem 2rem",

                    minHeight: "50vh",
                }}>
                <Row>
                    <Col
                        flex="1"
                        style={{ textAlign: "start", fontWeight: "bold" }}>
                        Total SMS count
                    </Col>
                    <Col
                        flex="5"
                        style={{
                            marginBottom: " 2rem",
                            flexFlow: "unset",

                            textAlign: "start",
                        }}>
                        {previewScreenData?.TotalSMSCount}
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <Row>
                            <Col
                                flex="1"
                                style={{
                                    textAlign: "start",
                                    fontWeight: "bold",
                                }}>
                                Sent To
                            </Col>
                            <Col
                                flex="5"
                                style={{
                                    marginBottom: " 2rem",
                                    flexFlow: "unset",
                                    textAlign: "start",
                                }}>
                                <Row>
                                    <b>Gender: </b> &nbsp;
                                    {previewScreenData?.Gender.map(
                                        (item, index) =>
                                            index ===
                                            previewScreenData?.Gender.length - 1
                                                ? ` ${item} `
                                                : ` ${item}, `
                                    )}
                                </Row>
                                <Row style={{ display: "inline-block" }}>
                                    <Col>
                                        <b>Factory:</b>&nbsp;
                                        {previewScreenData?.Factories.map(
                                            (item, index) =>
                                                index ===
                                                previewScreenData?.Factories
                                                    ?.length -
                                                    1
                                                    ? ` ${item.Code} `
                                                    : ` ${item.Code}, `
                                        )}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <b>Departments:</b>&nbsp;
                                        {result.map((item, index) =>
                                            index === result.length - 1
                                                ? ` ${toPascalCase(item)} `
                                                : ` ${toPascalCase(item)}, `
                                        )}
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <hr />
                <div className={styles.filterWrapper}>
                    <Select
                        value={previewDataInputs.language}
                        onChange={handleValueChange("language")}>
                        {previewScreenData?.language.map((val) => (
                            <Option value={val}>{val}</Option>
                        ))}
                    </Select>
                    <Select
                        value={previewDataInputs.factory}
                        placeholder="Factory"
                        onChange={handleValueChange("factory")}>
                        {previewScreenData?.Factories.map((val) => (
                            <Option value={val.id}>{val.Code}</Option>
                        ))}
                    </Select>
                    <Select
                        placeholder="Department"
                        value={getSubDeptValue()}
                        onChange={(value, option) => {
                            const [department, subDepartment] =
                                value.split("-");

                            handleValueChange("subDeptId")({
                                [department]: subDepartment,
                            });
                        }}>
                        {Object.keys(options)?.map((department) => (
                            <OptGroup label={department} key={department}>
                                {options[department].map((subDept) => (
                                    <Option
                                        key={`${department}-${subDept.SubDepartment}`}
                                        value={`${department}-${subDept.SubDepartment}`}>
                                        {toPascalCase(subDept.SubDepartment)}
                                    </Option>
                                ))}
                            </OptGroup>
                        ))}
                    </Select>
                    <Select
                        value={previewDataInputs.gender}
                        placeholder="Gender"
                        defaultActiveFirstOption={true}
                        onChange={handleValueChange("gender")}
                        style={{ width: "80%", marginRight: "1rem" }}>
                        {previewScreenData?.Gender.map((gender, index) => (
                            <Option key={index} value={gender}>
                                {gender}
                            </Option>
                        ))}
                    </Select>
                </div>

                <Row gutter={(12, 24)}>
                    <Col span={18}>
                        <Row>
                            <Col
                                flex="1"
                                style={{
                                    textAlign: "start",
                                    fontWeight: "bold",
                                }}>
                                Message Preview:
                            </Col>
                            <Col
                                flex="4"
                                style={{
                                    marginBottom: " 2rem",
                                    flexFlow: "unset",
                                }}>
                                {previewData?.FilteredPreview}
                            </Col>
                        </Row>
                    </Col>
                    <Col span={6}>
                        <Row>
                            <Col
                                flex="2"
                                style={{
                                    textAlign: "start",
                                    fontWeight: "bold",
                                }}>
                                Message Count
                            </Col>
                            <Col
                                flex="3"
                                style={{
                                    marginBottom: " 2rem",
                                    flexFlow: "unset",
                                }}>
                                {previewData?.FilteredCount}
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <div>
                    <hr />
                </div>
            </div>
            <Row justify="end">
                <Col>
                    <button
                        className="secondaryButton"
                        onClick={() => setActiveTab("2")}>
                        Back
                    </button>
                </Col>
                <Col>
                    <button
                        className="secondaryButton"
                        onClick={() => saveDraftHandler()}>
                        Save as Draft
                    </button>
                </Col>
                <Col>
                    <button
                        className="primaryButton"
                        onClick={() =>
                            showModal(
                                "broadcastMesaageSend",
                                "Broadcast message -  Send Message"
                            )
                        }>
                        Send Message
                    </button>
                </Col>
            </Row>
            <Modal
                title={<div className={styles.modalHeader}>Please Confirm</div>}
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
                footer={[
                    <button
                        key="confirm"
                        className="secondaryButton"
                        onClick={handleCancel}>
                        Cancel
                    </button>,
                    <button
                        key="confirm"
                        className="primaryButton"
                        onClick={handleOk}>
                        Confirm
                    </button>,
                ]}>
                <div className={styles.modalContent}>
                    <p>
                        Before sending, please double-check that your message is
                        complete and accurate. The message will be final and
                        cannot be modified after it's sent.
                    </p>
                </div>
            </Modal>
            <Modal
                title={<div className={styles.modalHeader}>Please Confirm</div>}
                open={isSkipModalOpen}
                onCancel={handleCancel}
                footer={null}
                width={400}>
                <div className={styles.modalContent}>
                    <p>
                        Time stamp for some factories has passed away, please
                        select the action you would like to take:
                    </p>
                    <ol>
                        <li>
                            Send the message right now, irrespective of the
                            timestamp.
                            <button
                                className={styles.confimButton}
                                onClick={() => sendMessageHandler("send")}>
                                Send Immediately
                            </button>
                        </li>
                        <li>
                            Skip Broadcast message will not be sent in the
                            listed factories.
                            <button
                                className={styles.confimButton}
                                onClick={() => sendMessageHandler("skip")}>
                                Skip Factories
                            </button>
                        </li>
                    </ol>
                </div>
            </Modal>
        </>
    );
};

export default PreviewMessage;
