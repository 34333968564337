import React from "react";
import { Tabs } from "antd";

export default function CaseReport({
    items,
    operations,
    activeTab,
    setActiveTab,
}) {
    return (
        <div>
            <Tabs
                tabBarExtraContent={operations}
                defaultActiveKey="1"
                activeKey={activeTab}
                items={items}
                onChange={(key) => {
                    setActiveTab(key);
                }}
                onTabClick={(key) => {
                    setActiveTab(key);
                }}
                style={{ width: "100%", padding: "20px" }}
            />
        </div>
    );
}
