import { Button, Card, Layout, Spin } from "antd";
import React from "react";
import CollapsableMenu from "../../components/CollapsableMenu";
import { Row, Col } from "antd";
import { useState, useEffect } from "react";
import AwarenessProgrammeUpload from "./AwarenessProgrammeUpload/index.jsx";
import AwarenessTable from "./AwarenessProgrammeTable/AwarenessTable/index";
import ButtonCard from "../../components/Home/TableContainer/ButtonContainer/ButtonCard";
import styles from "./Awareness.module.css";
import Dropdown from "../../@setproduct-ui/core/Dropdown";
import { userState } from "../../RecoilState/userState";
import { factoryState } from "../../RecoilState/FactoryState";
import { tokenState } from "../../RecoilState/tokenState";
import { FactoryData } from "../../RecoilState/FactoryData";
import { useRecoilState } from "recoil";
import { getAllFactoriesOfCompany } from "../../Adapters/FactoryAndCompanyCalls";
import FactoryListMenu from "../../components/FactoryListMenu";

const AwarnessProgramme = () => {
    const [uploadAwarenessFlag, setUploadAwarenessFlag] = useState(false);
    const [FactorySelected, setFactorySelected] = useRecoilState(factoryState);
    const [FactoryDataList, setFactoryDataList] = useRecoilState(FactoryData);
    const [user] = useRecoilState(userState);

    const [token, setToken] = useRecoilState(tokenState);
    // factory API call
    function setUploadFalse() {
        setUploadAwarenessFlag(false);
    }
    return (
        // comment
        <Layout>
            <CollapsableMenu></CollapsableMenu>
            <div className={styles.containerCard}>
                <Row
                    justify="space-between"
                    style={{
                        margin: "1rem 0 0 0",
                        display: "flex",
                        alignItems: "center",
                    }}>
                    <Col flex="60%">
                        <h1
                            id="AwarenessProgramme"
                            style={{
                                textAlign: "start",
                                padding: "1rem 0 0 2rem",
                            }}>
                            Awareness Programme
                        </h1>
                    </Col>

                    <Col flex="15%">
                        {(user.role === "SUPER_ADMIN" ||
                            user.role === "LEAD_SUPERVISOR" ||
                            user.role === "REGIONAL_ADMIN") && (
                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    marginLeft: "1rem",
                                }}>
                                {FactoryDataList ? (
                                    <Dropdown
                                        style={{
                                            height: "fit-Content",
                                            padding: "1rem",
                                        }}
                                        view="filled"
                                        color="primary"
                                        text={
                                            FactorySelected
                                                ? `${FactorySelected?.Code} - ${FactorySelected?.Location}`
                                                : `${FactoryDataList[0]?.Code} - ${FactoryDataList[0]?.Location}`
                                        }
                                        position="bottom-left"
                                        content={
                                            <FactoryListMenu
                                                factoryData={FactoryDataList}
                                            />
                                        }
                                        dense="false"
                                        className={styles.dropdown}
                                    />
                                ) : (
                                    <Spin />
                                )}
                            </div>
                        )}
                    </Col>
                    <Col flex="15%">
                        {!uploadAwarenessFlag &&
                            (user?.user_permissions?.includes(
                                "add_awarenessprogram"
                            ) ||
                                user?.group_permissions?.includes(
                                    "add_awarenessprogram"
                                )) && (
                                <Col>
                                    <div
                                        style={{
                                            float: "right",
                                            marginRight: "1rem",
                                        }}
                                        onClick={() => {
                                            setUploadAwarenessFlag(true);
                                        }}>
                                        <ButtonCard
                                            key="Upload Awareness Programme"
                                            text="Upload Awareness Programme"
                                            colorLogo="purple"
                                            icon="/assets/images/AwarenessProgramme/upload.svg"
                                        />
                                    </div>
                                </Col>
                            )}
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        {uploadAwarenessFlag === false ? (
                            <AwarenessTable />
                        ) : (
                            <AwarenessProgrammeUpload
                                setUploadFalse={setUploadFalse}
                            />
                        )}
                    </Col>
                </Row>
            </div>
        </Layout>
    );
};

export default AwarnessProgramme;
