import React, { useState, useCallback } from "react";
import { Input } from "antd";
import { SearchOutlined, LoadingOutlined } from "@ant-design/icons";
import debounce from "lodash/debounce";

const CustomSearch = ({
    onSearch,
    placeholder = "Search...",
    debounceTime = 300,
    styles = {},
    disabled = false,
}) => {
    const [value, setValue] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    // Debounced search function
    const debouncedSearch = useCallback(
        debounce(async (searchTerm) => {
            if (!searchTerm.trim()) {
                setIsLoading(false);
                return;
            }

            try {
                setIsLoading(true);
                setError(null);
                await onSearch(searchTerm);
            } catch (err) {
                setError("An error occurred while searching");
                console.error("Search error:", err);
            } finally {
                setIsLoading(false);
            }
        }, debounceTime),
        [onSearch]
    );

    const handleSearchChange = (e) => {
        setValue(e.target.value);
        debouncedSearch(e.target.value);
        if (!e.target.value.trim()) {
            onSearch("");
        } else {
            debouncedSearch(e.target.value);
        }
    };

    return (
        <div>
            <Input
                value={value}
                onChange={handleSearchChange}
                placeholder={placeholder}
                prefix={isLoading ? <LoadingOutlined /> : <SearchOutlined />}
                allowClear
                disabled={disabled}
                onPressEnter={() => onSearch(value)}
                status={error ? "error" : ""}
                style={{
                    ...styles.input,
                    width: styles.width || "300px",
                }}
            />
            {error && (
                <div
                    style={{
                        color: "#ff4d4f",
                        fontSize: "12px",
                        marginTop: "2px",
                    }}>
                    {error}
                </div>
            )}
        </div>
    );
};

export default CustomSearch;
