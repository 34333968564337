import React from "react";
import { useRecoilState } from "recoil";
import { userState } from "../../RecoilState/userState.js";

import OfflineTable from "../../components/OfflineTable.jsx";
import DashboardLayout from "../../components/DashboardLayout/DashboardLayout.jsx";
import CustomButton from "../../components/Utils/CustomButton.jsx";
import { useNavigate } from "react-router";

const OfflineDashboard = () => {
    const navigate = useNavigate();
    const [user] = useRecoilState(userState);

    // image for the case upload button
    const image = (
        <img src="/assets/images/ButtonLogos/icon/action/note_add_24px.svg" />
    );
    const hasPermission = user?.group_permissions?.includes("add_offlinecase");

    const handleButtonClick = () => {
        navigate("/OfflineCaseUpload");
    };

    // object containing props to send into the dashboard layout component
    const dashboardProps = {
        heading: "Offline Cases",
        ...(hasPermission && {
            buttons: (
                <CustomButton
                    image={image}
                    text="Case Upload"
                    onClick={handleButtonClick}
                />
            ),
        }),
        body: <OfflineTable user={user} />,
    };

    return <DashboardLayout {...dashboardProps} />;
};

export default OfflineDashboard;
