import React from "react";
import { Button } from "antd";

const CustomButton = ({ image, text, colour = null, onClick }) => {
    const buttonStyles = {
        height: "3rem",
        paddingRight: "2rem",
        paddingLeft: "2rem",
        background: colour
            ? colour
            : "linear-gradient(to bottom right, #9EAAD1, #0726D4)",
        color: colour ? "black" : "white",
        borderRadius: "10px",
        fontFamily: "Poppins",
    };

    return (
        <Button
            data-testid="custom-button"
            icon={image}
            style={buttonStyles}
            onClick={onClick}>
            {text}
        </Button>
    );
};

export default CustomButton;
