import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { Table, Tabs } from "antd";
import _ from "lodash";

import { getOfflineCasesData } from "../Adapters/OfflineCasesCalls.js";
import ContainerLayout from "../containers/ContainerLayout/ContainerLayout.jsx";
import CustomSearch from "./Utils/CustomSearch.jsx";
import LoggedInComponent from "../containers/LoggedInComponent/LoggedInComponent.jsx";

const OfflineTable = ({ user }) => {
    const [tableData, setTableData] = useState([]);
    const [activeTab, setActiveTab] = useState("1");
    const [filteredTableData, setFilteredTableData] = useState(tableData);
    const navigate = useNavigate();

    // filter function for the table
    const filterData = (data, formatter) =>
        _.uniqBy(data, formatter).map((item, index) => ({
            text: formatter(item),
            value: formatter(item),
            key: `${formatter(item)}_${index}`,
        }));

    const handleSearch = (searchTerm) => {
        if (!searchTerm.trim()) {
            setFilteredTableData(tableData);
            return;
        }
        const filteredData = tableData.filter((record) =>
            Object.values(record).some((value) =>
                value
                    .toString()
                    .toLowerCase()
                    .includes(searchTerm.toLowerCase())
            )
        );
        setFilteredTableData(filteredData);
    };

    // array with tab names, this will mapped to return each tab with its own table
    const tabNames = ["In Person", "Suggestion Box", "Worker Committee"];

    const columns = [
        {
            title: "Case Number",
            dataIndex: "case_number",
            key: "caseNumber",
            filterMode: "tree",
            filterSearch: true,
            sorter: (a, b) => a.case_number.localeCompare(b.case_number),
            filters: filterData(tableData, (item) => item.case_number),
            onFilter: (value, record) => {
                const searchValue = value.toLowerCase().trim();
                const caseNumber = record.case_number
                    .toString()
                    .toLowerCase()
                    .trim();
                return caseNumber.indexOf(searchValue) !== -1;
            },
            width: "14%",
        },
        {
            title: "Issue Date",
            dataIndex: "date",
            key: "issueDate",
            filterMode: "tree",
            filterSearch: true,

            render: (text, record) => record.date,
            filters: filterData(tableData, (item) => item.date),
            onFilter: (value, record) => {
                const formattedDate = record.date;
                return formattedDate === value;
            },
            width: "16%",
        },
        {
            title: "Case Nature",
            dataIndex: "case_nature",
            key: "caseNature",
            filterMode: "menu",
            sorter: (a, b) => a.case_nature.localeCompare(b.case_nature),
            filters: filterData(tableData, (item) => item.case_nature),
            onFilter: (value, record) => record.case_nature.includes(value),
            width: "14%",
        },
        {
            title: "Category",
            dataIndex: "category__category",
            key: "category",
            filterMode: "menu",
            filters: filterData(tableData, (item) => item.category__category),
            onFilter: (value, record) =>
                record.category__category.includes(value),
            width: "14%",
        },
        {
            title: "Sub-Category",
            dataIndex: "category__sub_category",
            key: "subCategory",
            filterMode: "menu",
            sorter: (a, b) =>
                a.category__sub_category.localeCompare(
                    b.category__sub_category
                ),
            filters: filterData(
                tableData,
                (item) => item.category__sub_category
            ),
            onFilter: (value, record) =>
                record.category__sub_category.includes(value),
            width: "14%",
        },
        {
            title: "Resolution Date",
            dataIndex: "resolution_date",
            key: "resolutionDate",
            filterMode: "tree",
            filterSearch: true,

            render: (text, record) => record.resolution_date,
            filters: filterData(tableData, (item) => item.resolution_date),
            onFilter: (value, record) => {
                const formattedDate = record.resolution_date;
                return formattedDate === value;
            },
            width: "16%",
        },
    ];

    // to fetch offline cases, this will send in the url to the api call
    const fetchOfflineCases = async () => {
        const caseNature =
            activeTab === "1"
                ? "In Person"
                : activeTab === "2"
                ? "Suggestion Box"
                : "Worker Committee";

        let url = new URL(
            `${process.env.REACT_APP_BASE_URL_API}/api/accounts/OfflineCase/?case_type=${caseNature}&`
        );

        // if user is CR, factory id is required for the API call, and if user is SA or LS, compnay id
        if (user?.role === "CR") {
            url += `factory=${user?.factory_fk}`;
        } else if (["SUPER_ADMIN", "LEAD_SUPERVISOR"].includes(user?.role)) {
            url += `company=${user?.company_fk}`;
        }

        try {
            const res = await getOfflineCasesData(url);
            if (res?.data.message_body.Cases) {
                setTableData(res.data.message_body.Cases);
            }
        } catch (error) {
            console.log(error.message);
        }
    };

    const handleTabChange = (key) => {
        setActiveTab(key);
    };

    useEffect(() => {
        fetchOfflineCases();
    }, [activeTab]);

    useEffect(() => {
        setFilteredTableData(tableData);
    }, [tableData]);

    // on clicking an item, navigate to case report, and will send in the case id
    const handleRowClick = (record) => {
        navigate(`/OfflineCaseReport/${record.id}`, {
            state: { id: record.id },
        });
    };

    const pagination = {
        pageSize: 10,
        total: filteredTableData.length,
        showTotal: (total) => `Total Count: ${total}`,
    };

    // creating the items array based on tabs
    const items = tabNames.map((tabName, index) => {
        return {
            key: (index + 1).toString(),
            label: tabName,
            children: (
                <div style={{ height: "100%" }}>
                    <Table
                        columns={columns}
                        dataSource={filteredTableData}
                        rowKey="id"
                        scroll={{ y: 350 }}
                        pagination={pagination}
                        showSizeChanger={false}
                        onRow={(record) => ({
                            onClick: () => handleRowClick(record),
                        })}
                    />
                </div>
            ),
        };
    });

    return (
        <LoggedInComponent>
            <div style={{ height: "100%" }}>
                <ContainerLayout>
                    <Tabs
                        defaultActiveKey="1"
                        onChange={handleTabChange}
                        items={items}
                        tabBarExtraContent={
                            <CustomSearch
                                onSearch={handleSearch}
                                styles={{
                                    input: {
                                        border: 0,
                                        borderBottom: "1px solid #1B2559",
                                    },
                                    width: "200px",
                                }}
                                placeholder="Search case number"
                            />
                        }></Tabs>
                </ContainerLayout>
            </div>
        </LoggedInComponent>
    );
};

export default OfflineTable;
