import React, { useEffect, useState } from "react";
import CaseUploadForm from "../../../components/Utils/CaseUploadForm";
import { uploadOfflineCase } from "../../../Adapters/OfflineCasesCalls";
import { useRecoilState } from "recoil";
import { userState } from "../../../RecoilState/userState";
import useCategories from "../../../hooks/useCategories";
import { Form, message } from "antd";
import dayjs from "dayjs";
import { useNavigate } from "react-router";

const warningMessage =
    "Please avoid including any personal information about the worker in the “Case Details” or “Remarks”; such as their name, ID, or phone number. This is to ensure their privacy and anonymity.";

const InPersonOfflineCaseUpload = ({ departmentValues }) => {
    const navigate = useNavigate();
    const [isDocumentUploaded, setIsDocumentUploaded] = useState(false);
    const [documentFileList, setDocumentFileList] = useState([]);
    const [uploadFinished, setUploadFinished] = useState(false);
    const onUploadComplete = () => {
        setUploadFinished(true);
        navigate("/home/OfflineCases");
    };
    const [uploadedFileID, setUploadedFileID] = useState("");
    const [user] = useRecoilState(userState);
    const { categoryOptions, subCategoryOptions, updateSubCategoryOptions } =
        useCategories(user.company_fk);

    const [form] = Form.useForm();
    const fieldsData = [
        {
            type: "date",
            label: "Issue Date",
            name: "date",
            colSpan: 12,
            placeholder: "Select Issue Date",
            rules: [{ required: true, message: "Please select issue date!" }],
        },
        {
            type: "select",
            label: "Concerned Dept.",
            name: "concerned_dept",
            colSpan: 12,
            options: departmentValues,
            placeholder: "Select Concerned Dept",
            rules: [
                { required: true, message: "Please select concerned dept!" },
            ],
        },
        {
            type: "input",
            label: "Worker Name",
            name: "worker_name",
            colSpan: 12,
            placeholder: "Enter Worker Name",
            rules: [{ required: true, message: "Please input worker name!" }],
        },
        {
            type: "date",
            label: "Resolution Date",
            name: "resolution_date",
            colSpan: 12,
            placeholder: "Select Resolution Date",
            rules: [
                { required: true, message: "Please select resolution date!" },
            ],
        },
        {
            type: "select",
            label: "Medium",
            name: "medium",
            colSpan: 12,
            options: [
                { label: "Handwritten", value: "Handwritten" },
                { label: "Call", value: "Call" },
                { label: "SMS", value: "SMS" },
                { label: "WhatsApp", value: "WhatsApp" },
                { label: "Other", value: "Other" },
            ],
            placeholder: "Select Medium",
        },
        {
            type: "input",
            label: "Resolution Provided",
            name: "resolution_provided",
            colSpan: 12,
            placeholder: "Enter Resolution",
            rules: [
                {
                    required: true,
                    message: "Please select Resolution Provided",
                },
            ],
        },
        {
            type: "select",
            label: "Case Nature",
            name: "case_nature",
            colSpan: 12,
            options: [
                { label: "Complaint", value: "Complaint" },
                { label: "Query", value: "Query" },
                { label: "Suggestion", value: "Suggestion" },
            ],
            placeholder: "Select Case Nature",
            rules: [{ required: true, message: "Please select case nature!" }],
        },
        {
            type: "upload",
            label: "Documents",
            name: "file",
            model: "offline_documents",
            allowedFileTypes: [
                "pdf",
                "doc",
                "docx",
                "jpeg",
                "jpg",
                "png",
                "svg",
            ],
            colSpan: 12,
            placeholder: "Attach supporting media",
            fileList: documentFileList,
            setFileList: setDocumentFileList,
            handleUpload: isDocumentUploaded,
            setHandleUpload: setIsDocumentUploaded,
            onUploadComplete: onUploadComplete,
        },
        {
            type: "select",
            label: "Category",
            name: "category",
            colSpan: 12,
            options: categoryOptions,
            placeholder: "Select Category",
            rules: [{ required: true, message: "Please select category!" }],
        },
        {
            type: "textarea",
            label: "Case Details",
            name: "case_details",
            colSpan: 12,
            placeholder: "Enter Case Details",
            rules: [
                { required: true, message: "Please provide case details!" },
            ],
        },
        {
            type: "select",
            label: "Sub Category",
            name: "subCategory",
            colSpan: 12,
            options: subCategoryOptions,
            placeholder: "Select Sub Category",
            rules: [{ required: true, message: "Please select sub category!" }],
        },

        {
            type: "textarea",
            label: "Remarks",
            name: "remarks",
            colSpan: 12,
            placeholder: "Enter Remarks",
        },
    ];
    const resetFields = () => {
        form.resetFields();
        setUploadFinished(false);
        if (documentFileList.length <= 0) {
            navigate("/home/OfflineCases");
        }
    };
    const handleSubmit = async (values) => {
        try {
            const data = {
                factory: user.factory_fk,
                date: dayjs(values.date).format("YYYY-MM-DD"),
                concerned_dept: values.concerned_dept,
                worker_name: values.worker_name,
                resolution_date: dayjs(values.resolution_date).format(
                    "YYYY-MM-DD"
                ),
                medium: values.medium,
                resolution_provided: values.resolution_provided,
                case_nature: values.case_nature,
                category: values.subCategory,
                case_details: values.case_details,
                remarks: values.remarks,
                case_type: "In Person",
            };
            const res = await uploadOfflineCase(data);
            setUploadedFileID(res.data.id);
            setIsDocumentUploaded(true);
            resetFields();
            message.success(res.data.message);
        } catch (error) {
            console.error("Error uploading case:", error);
        }
    };

    const handleValuesChange = (changedValues) => {
        if (changedValues.category) {
            updateSubCategoryOptions(changedValues.category);
            form.setFieldsValue({ subCategory: undefined }); // Reset subcategory field
        }
    };

    return (
        <div>
            <CaseUploadForm
                form={form}
                fieldsData={fieldsData}
                handleSubmit={handleSubmit}
                handleValuesChange={handleValuesChange}
                warningMessage={warningMessage}
                caseID={uploadedFileID}
            />
        </div>
    );
};

export default InPersonOfflineCaseUpload;
