import React, { useState, useEffect } from "react";
import { Modal, Table } from "antd";
import _ from "lodash";

import {
    getScheduledMessages,
    getScheduledMessageByID,
} from "../../../Adapters/BroadcastmessageCalls";
import DateTimeFormatter from "../../../components/Utils/DateTimeFormatter";
import DraftModal from "../DraftMsg/DraftModal";

const ScheduledMsg = ({ mainTabKey }) => {
    const [tableData, setTableData] = useState();
    const [modalData, setModalData] = useState();
    const [open, setOpen] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);

    const filterData = (data, formatter) =>
        _.uniqBy(data, formatter).map((item) => ({
            text: formatter(item),
            value: formatter(item),
        }));

    const columns = [
        {
            title: "Date and time",
            dataIndex: "lastModified",
            key: "lastModified",
            filterMode: "tree",
            filterSearch: true,

            render: (text, record) => (
                <DateTimeFormatter
                    dateTimeString={`${record.Date} ${record.Time}`}
                />
            ),
            sorter: (a, b) =>
                new Date(`${a.Date} ${a.Time}`).getTime() -
                new Date(`${b.Date} ${b.Time}`).getTime(),
            filters: filterData(
                tableData,
                (item) => `${item.Date} ${item.Time}`
            ),
            onFilter: (value, record) => {
                const formattedDate = `${record.Date} ${record.Time}`;
                return formattedDate === value;
            },
            width: "16%",
        },
        {
            title: "Created By",
            dataIndex: "createdBy",
            key: "createdBy",
            filterMode: "tree",
            filterSearch: true,
            filters: filterData(tableData, (item) => item.createdBy),
            onFilter: (value, record) => {
                const searchValue = value.toLowerCase().trim();
                const createdBy = record.createdBy
                    .toString()
                    .toLowerCase()
                    .trim();
                return createdBy.indexOf(searchValue) !== -1;
            },
            width: "14%",
        },
        {
            title: "Factory(ies)",
            dataIndex: "factories",
            key: "factories",
            filterMode: "tree",
            filterSearch: true,
            filters: filterData(tableData, (item) => item.factories),
            onFilter: (value, record) =>
                record.factories.toLowerCase().includes(value.toLowerCase()),
            width: "16%",
        },
        {
            title: "Languages",
            dataIndex: "languages",
            key: "languages",
            filterMode: "menu",
            sorter: (a, b) => a.languages - b.languages,
            filters: filterData(tableData, (item) => item.languages),
            onFilter: (value, record) => record.languages.includes(value),
            width: "14%",
        },
        {
            title: "Template",
            dataIndex: "templateTitle",
            key: "templateTitle",
            filterMode: "menu",
            sorter: (a, b) => a.templateTitle - b.templateTitle,
            filters: filterData(tableData, (item) => item.templateTitle),
            onFilter: (value, record) => record.templateTitle.includes(value),
            width: "14%",
        },
        {
            title: "Message",
            dataIndex: "Message",
            key: "Message",
            width: "26%",
        },
    ];

    const pagination = {
        pageSize: 5,
    };

    const fetchScheduledMessages = async () => {
        try {
            const { data } = await getScheduledMessages();
            setTableData(data?.message_body);
        } catch (error) {
            console.log(error.message);
        }
    };

    useEffect(() => {
        if (mainTabKey === "2") {
            fetchScheduledMessages();
        }
    }, [mainTabKey]);

    const handleRowClick = async (record) => {
        try {
            const data = await getScheduledMessageByID(record.id);
            setModalData(data.message_body);
        } catch (error) {
            console.log(error.message);
        }

        showModal();
    };

    const showModal = () => {
        setOpen(true);
    };

    const handleOk = () => {
        setConfirmLoading(true);
        setTimeout(() => {
            setOpen(false);
            setConfirmLoading(false);
        }, 2000);
    };

    const handleCancel = () => {
        setOpen(false);
    };

    return (
        <div style={{ height: "100%" }}>
            <Table
                rowKey="id"
                scroll={{ y: 400 }}
                columns={columns}
                dataSource={tableData}
                pagination={pagination}
                onRow={(record) => ({
                    onClick: () => handleRowClick(record),
                })}
            />

            <Modal
                width={900}
                centered
                title="Scheduled Message Details"
                open={open}
                onOk={handleOk}
                confirmLoading={confirmLoading}
                onCancel={handleCancel}
                destroyOnClose={true}
                footer={false}>
                <DraftModal
                    draftModalData={modalData}
                    messageID={modalData?.messageID}
                    mainTabKey={mainTabKey}
                />
            </Modal>
        </div>
    );
};

export default ScheduledMsg;
