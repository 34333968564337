import React, { Children } from "react";
import { Input, message, InputNumber, Form, Row, Col, Select } from "antd";
import styles from "../CaseUpload.module.css";
import { userState } from "../../../RecoilState/userState";
import { useRecoilState } from "recoil";
import { useEffect } from "react";
import {
    getNumberOfCases,
    inPersonCaseUploaderWithoutFile,
} from "../../../Adapters/CaseCRUDCalls";
import { getAllFactoriesOfCompany } from "../../../Adapters/FactoryAndCompanyCalls";
import { tokenState } from "../../../RecoilState/tokenState";

import { FactoryData } from "../../../RecoilState/FactoryData";
import { factoryState } from "../../../RecoilState/FactoryState";
import useEventMetrics from "../../../hooks/useEventMetricsUpdater";
import useEventMetricsUpdater from "../../../hooks/useEventMetricsUpdater";
import { debounce } from "lodash";

let key = "caseuploadinperson";
const { Option } = Select;

export default function InPerson({ modalCloser, languageList }) {
    const [MessageByWorker, setMessageByWorker] = React.useState("");
    const [token, setToken] = useRecoilState(tokenState);
    const [user, setUser] = useRecoilState(userState);

    const [FactoryList, setFactoryList] = useRecoilState(FactoryData);
    const [caseUploaded, setCaseUploaded] = React.useState(false);
    const [caseNumber, setCaseNumber] = React.useState("");
    const [inpersonForm] = Form.useForm();
    const { updateEventMetrics } = useEventMetricsUpdater();
    const [loader, setLoader] = React.useState(false);
    // Factory API call

    useEffect(() => {
        inpersonForm.setFieldsValue({
            Factory: user?.factory_fk,
        });
    }, [user]);

    function handleAnotherCase() {
        setCaseUploaded(false);
        resetFields();
    }

    function resetFields() {
        inpersonForm.setFieldsValue({
            Factory: user?.factory_fk,
            Comments: "",
            MessageByWorker: "",
            workersLanguage: "",
            PhoneNo: null,
        });
    }

    const handleCaseUpload = (eventName, actionType) => {
        // Your existing logic...
        message.loading({ content: "Loading...", key });
        setLoader(true);
        getNumberOfCases().then((res) => {
            let caseObject = {
                Company: user?.company_fk,
                Factory: inpersonForm.getFieldValue("Factory"),
                CommentsByRep: inpersonForm.getFieldValue("Comments"),
                ReportingMedium: "In Person",
                CaseStatus: "Assigned to Reporter",
                Tag: "inperson",
                MessagebyWorker: inpersonForm.getFieldValue("MessageByWorker"),
                PhoneNo: inpersonForm.getFieldValue("PhoneNo").toString(),
                workerLanguage: inpersonForm.getFieldValue("workersLanguage"),
            };

            inPersonCaseUploaderWithoutFile(caseObject)
                .then((res) => {
                    message.success({
                        content: "Case Uploaded successfully!",
                        key,
                        duration: 2,
                    });
                    setCaseNumber(res.data.CaseNumber);
                    setCaseUploaded(true);
                    resetFields();
                    setLoader(false);
                })
                .catch((error) => {
                    setLoader(false);
                    error?.response?.data?.errorMessage
                        ? message.error(error?.response?.data?.errorMessage)
                        : message.error(
                              "Unable to upload case, please check all the fields"
                          );
                });
        });
        updateEventMetrics(eventName, actionType);
    };
    const debouncedSubmit = debounce(handleCaseUpload, 500);
    if (caseUploaded === true) {
        return (
            <div>
                <div className={styles.successfulUpload}>
                    <h3>Case is uploaded successfully!</h3>
                    <h1>Case Number - {caseNumber}</h1>
                    <p>
                        Enquiry about the case can be done by using this case
                        number. Make sure to not share it with any unknown
                        individual or authority. If you have submitted your
                        phone number, updates will be messaged to it.
                    </p>
                </div>
                <div className={styles.divider}></div>
                <div className={styles.anotherCasebtCont}>
                    <button
                        onClick={handleAnotherCase}
                        className={styles.anotherCaseButton}>
                        Upload another case
                    </button>
                </div>
            </div>
        );
    } else {
        return (
            <Form
                form={inpersonForm}
                id="inpersonForm"
                name="inpersonForm"
                onFinish={() =>
                    debouncedSubmit(
                        "caseUploadSubmit",
                        "In-person Case upload submit"
                    )
                }>
                <Row
                    gutter={[70, 40]}
                    align="top"
                    className={styles.topRowElements}>
                    <Col span={12}>
                        <Form.Item
                            name="Factory"
                            label="Select Factory"
                            className={styles.label}
                            rules={[
                                {
                                    required: true,
                                    message: "Please select Factory",
                                },
                            ]}>
                            <Select
                                disabled={
                                    user.role === "SUPER_ADMIN" ? false : true
                                }>
                                {FactoryList.length > 0 &&
                                    FactoryList.map((FactoryItem, index) => (
                                        <Option
                                            key={index}
                                            value={FactoryItem.id}>
                                            {`${FactoryItem.Code}-${FactoryItem.Location}`}
                                        </Option>
                                    ))}
                            </Select>
                        </Form.Item>
                    </Col>

                    <Col span={12}>
                        <Form.Item
                            name="MessageByWorker"
                            label="Complaint/ Query/ Suggestion"
                            value={MessageByWorker}
                            rules={[
                                {
                                    required: true,
                                    message:
                                        "Please Input Complaint/Query/Suggestion",
                                },
                            ]}>
                            <div>
                                <Input.TextArea
                                    rows={4}
                                    maxLength={300}
                                    showCount
                                />
                            </div>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[70, 40]}>
                    <Col span={12}>
                        <Form.Item
                            name="PhoneNo"
                            label="Phone Number"
                            className="phoneNo"
                            rules={[
                                {
                                    pattern: /^[0-9]+$/,
                                    message: "Please input numbers only!",
                                },
                                {
                                    required: true,
                                    message: "Please enter a phone number",
                                },
                                {
                                    len: 10,
                                    message: "Please enter 10 digits",
                                },
                            ]}>
                            <Col>
                                <InputNumber
                                    maxLength={10}
                                    prefix="+91"
                                    controls={false}
                                    style={{
                                        width: "100%",
                                    }}
                                />
                                <p>
                                    (Recommended so as to get updates about the
                                    case but is not compulsory.) The phone
                                    number will always be masked to ensure
                                    privacy is maintained.
                                </p>
                            </Col>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="Comments"
                            label="Comments"
                            rules={[
                                {
                                    required: true,
                                    message: "Please Input Comments",
                                },
                            ]}>
                            <div>
                                <Input.TextArea
                                    rows={4}
                                    span={20}
                                    maxLength={300}
                                    showCount
                                />
                            </div>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[70, 40]}>
                    <Col span={12}>
                        <Form.Item
                            name="workersLanguage"
                            label="Worker's Language"
                            className={styles.label}
                            rules={[
                                {
                                    required: true,
                                    message: "Please select Language",
                                },
                            ]}>
                            <Select>
                                {languageList?.languages?.map((lang, index) => (
                                    <>
                                        <Option key={index} value={lang}>
                                            {lang}
                                        </Option>
                                    </>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <div className={styles.divider}></div>
                <Row>
                    <Col style={{ padding: "1%" }}>
                        <span style={{ color: "red" }}>*</span> means mandatory
                    </Col>
                    <Col span={20} offset={12} style={{ padding: "1%" }}>
                        <button
                            disabled={loader}
                            id="submitButton"
                            className={
                                loader
                                    ? `${styles.submitButton} ${styles.disabled}`
                                    : styles.submitButton
                            }
                            title="Submit">
                            Submit
                        </button>
                    </Col>
                </Row>
            </Form>
        );
    }
}
