import React from "react";
import { Modal } from "antd";
import styles from "./ActionModal.module.css";

const ConfirmationModal = ({
    isOpen,
    onCancel,
    title,
    content,
    actions,
    width = 400,
}) => {
    return (
        <Modal
            title={<div className={styles.modalHeader}>{title}</div>}
            open={isOpen}
            onCancel={onCancel}
            footer={null}
            width={width}>
            <div className={styles.modalContent}>
                <p>{content}</p>
                <div className={styles.modalActions}>
                    <button className="secondaryButton" onClick={onCancel}>
                        Cancel
                    </button>
                    <button
                        className="primaryButton"
                        onClick={actions.confirm.handler}>
                        Confirm
                    </button>
                </div>
            </div>
        </Modal>
    );
};

export default ConfirmationModal;
