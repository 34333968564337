export const getFullTimeZoneFormat = (timeZones, extractedTimeZone) => {
    return (
        timeZones?.find((tz) => tz.includes(extractedTimeZone)) ||
        extractedTimeZone
    );
};
/**
 * Extracts the time zone string from a given input string.
 *
 * The regular expression used in this function matches the following pattern:
 * - A GMT offset enclosed in parentheses, e.g., (GMT +08:00)
 * - One or more whitespace characters
 * - A time zone abbreviation or numeric value, e.g., CDT, +08
 * - One or more whitespace characters
 * - A time zone name, e.g., Cuba, Asia/Ulaanbaatar
 *
 * Regular Expression Breakdown:
 * - `\((GMT [+-]\d{2}:\d{2})\)`: Matches the GMT offset enclosed in parentheses.
 *   - `\(` and `\)`: Match the literal parentheses.
 *   - `GMT [+-]\d{2}:\d{2}`: Matches the GMT offset.
 *     - `GMT`: Matches the literal string "GMT".
 *     - `[+-]`: Matches either a plus (+) or minus (-) sign.
 *     - `\d{2}`: Matches exactly two digits.
 *     - `:`: Matches the literal colon character.
 *     - `\d{2}`: Matches exactly two digits.
 * - `\s+`: Matches one or more whitespace characters.
 * - `([A-Za-z0-9+\s]+)`: Matches the time zone abbreviation or numeric value.
 *   - `[A-Za-z0-9+\s]`: Matches any uppercase or lowercase letter, digit, plus sign (+), or whitespace character.
 *   - `+`: Matches one or more of the preceding characters.
 * - `\s+`: Matches one or more whitespace characters.
 * - `([A-Za-z_/]+)`: Matches the time zone name.
 *   - `[A-Za-z_/]`: Matches any uppercase or lowercase letter, underscore (_), or forward slash (/).
 *   - `+`: Matches one or more of the preceding characters.
 *
 * @param {string} str - The input string containing the time zone information.
 * @returns {string|null} - The extracted time zone string or null if no match is found.
 */
export const extractTimeZone = (str) => {
    const match = str.match(
        /\((GMT [+-]\d{2}:\d{2})\)\s+([A-Za-z0-9+\s]+)\s+([A-Za-z_/]+)/
    );
    return match ? match[3] : null;
};

export const handleTimeZoneSearch = (timeZones, value, setTimeZoneOptions) => {
    if (value.length) {
        const filteredTimezones = timeZones.filter((timezone) =>
            new RegExp(value.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"), "i").test(
                timezone
            )
        );
        setTimeZoneOptions(() => {
            return filteredTimezones.map((timeZone) => ({
                label: timeZone,
                value: timeZone,
            }));
        });
    }
};
