import React from "react";
import { useRecoilState } from "recoil";
import { userState } from "../../RecoilState/userState";

// Component to format and display the date/time
const DateTimeFormatter = ({ dateTimeString, requiredField }) => {
    const [user, setUser] = useRecoilState(userState);
    const timezone = user.timezone || "UTC";
    if (!dateTimeString) return <span>Not Available</span>;

    const formattedDate = formatDate(dateTimeString, requiredField, timezone);
    return <span>{formattedDate}</span>;
};

// Function to format the date and time
export const formatDate = (dateTimeString, requiredField, timezone) => {
    const date = new Date(dateTimeString);

    // Timezone conversion for based om give time zone
    const options = {
        timeZone: timezone,
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        hour12: true, // Using 12-hour format with AM/PM
    };

    // Get the formatted date/time string based on the options
    const formattedDateTime = new Intl.DateTimeFormat("en-US", options).format(
        date
    );

    // Return only the date if required, otherwise return the full formatted string
    if (requiredField === "date") {
        return formattedDateTime.split(",")[0]; // Just the date part
    } else if (requiredField === "time") {
        return formattedDateTime.split(",")[1]; // Just the time part
    }
    return formattedDateTime; // Full date and time with time zone applied
};

export default DateTimeFormatter;
