// Manages User login with Cookie and token
// Check cookie and keeps user logged in based on cookie

import React, { useEffect, useState } from "react";
import { Cookies, useCookies } from "react-cookie";
import jwt_decode from "jwt-decode";
import { useRecoilState } from "recoil";
import { tokenState } from "../../RecoilState/tokenState";
import { userState } from "../../RecoilState/userState";
import { getUserDetails } from "../../Adapters/userCalls";
import { message, Spin } from "antd";
import { currentSelectedRoleState } from "../../RecoilState/GlobalUserRoleStates/currentSelectedRoleState";
import { setAvailableRolesFunction } from "../../pages/Login";
import { availableUserRoleStates } from "../../RecoilState/GlobalUserRoleStates/availableUserRoleStates";
import { prevTokenState } from "../../RecoilState/userTokenState";
export default function LoggedInComponent({ children }) {
    const [roleCookie, setRoleCookie, removeRoleCookie] = useCookies([
        "role-token-cookie",
    ]);
    const [user, setUser] = useRecoilState(userState);
    const [loading, setLoading] = useState(false);
    const [token, setToken] = useRecoilState(tokenState);
    const [prevToken, setPrevToken] = useRecoilState(prevTokenState);
    const [currentSelectedRole, setCurrentSelectedRole] = useRecoilState(
        currentSelectedRoleState
    );
    const cookies = new Cookies();
    const [availableUserRoles, setAvailableUserRoles] = useRecoilState(
        availableUserRoleStates
    );

    useEffect(() => {
        if (roleCookie["role-token-cookie"] != null || undefined || "") {
            const jwtPayload = JSON.parse(
                window.atob(
                    roleCookie["role-token-cookie"]?.refresh?.split(".")[1]
                )
            );

            setToken({
                ...roleCookie["role-token-cookie"],
                expires: jwtPayload?.exp,
            });
        } else {
            console.log("token not in cookie");
        }
    }, [token?.access]);

    useEffect(() => {
        if (
            (localStorage.getItem("available-role-cookie") != null ||
                undefined ||
                "") &&
            (availableUserRoles === undefined || null || "")
        ) {
            setAvailableUserRoles(
                JSON.parse(localStorage.getItem("available-role-cookie"))
            );
        }
    }, [localStorage.getItem("available-role-cookie")]);

    useEffect(() => {
        if (availableUserRoles) {
            localStorage.setItem(
                "available-role-cookie",
                JSON.stringify(availableUserRoles)
            );
            localStorage.setItem("flag", true);
        }
    }, []);

    useEffect(() => {
        if (
            JSON.parse(localStorage.getItem("current-selected-role")) !=
            currentSelectedRole
        ) {
            localStorage.setItem(
                "current-selected-role",
                JSON.stringify(currentSelectedRole)
            );
        }
    }, [currentSelectedRole]);

    useEffect(() => {
        try {
            if (roleCookie["role-token-cookie"]?.access || token?.access) {
                var decoded = jwt_decode(token?.access);
                setLoading(true);
                if (decoded.role_id) {
                    if (
                        !prevToken?.access ||
                        prevToken?.access != token?.access
                    ) {
                        getUserDetails(decoded.user_id, token)
                            .then((res) => {
                                setUser((prevUserDetails) => ({
                                    ...prevUserDetails,
                                    ...res.data.message_body.User,
                                }));
                                setPrevToken(token);
                                setLoading(false);
                                if (cookies.get("token")) {
                                    setAvailableRolesFunction(
                                        res.data.message_body,
                                        setAvailableUserRoles
                                    );
                                    setCurrentSelectedRole(currentSelectedRole);
                                }
                            })
                            .catch((err) => {
                                console.log(err, "ERROR User details error");
                                setLoading(false);
                            });
                    } else {
                        setLoading(false);
                    }
                }
            } else {
                throw "Token Not present, Please login";
            }
        } catch (err) {
            if (err == "Token Not present, Please login") {
                message.error("Please try logging in again");
            } else {
                console.log("Login error", err);
            }
        }
        return () => {};
    }, [token?.access]);

    useEffect(() => {
        setUser((prevUserDetails) => ({
            ...prevUserDetails,
            factory_fk: currentSelectedRole?.factory_fk,
            role: currentSelectedRole?.role,
            group_permissions: currentSelectedRole?.group_permissions,
            user_permissions: currentSelectedRole?.user_permissions,
            roleId: currentSelectedRole?.id,
            code: currentSelectedRole?.Code,
            region: currentSelectedRole.region,
            region_fk: currentSelectedRole?.region_fk, //added region_fk in global user state
            timezone: currentSelectedRole?.timezone,
        }));
    }, [currentSelectedRole]);

    useEffect(() => {
        if (
            localStorage.getItem("current-selected-role") &&
            (currentSelectedRole === undefined || null || "")
        ) {
            setCurrentSelectedRole(
                JSON.parse(localStorage.getItem("current-selected-role"))
            );
        }
    }, []);

    if (loading) {
        return <Spin size="large" />;
    }
    return <>{children}</>;
}
