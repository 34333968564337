import React from "react";
import { Row, Layout, Col } from "antd";

import CollapsableMenu from "../CollapsableMenu";
import styles from "./DashboardLayout.module.css";

const DashboardLayout = ({ heading, buttons, body }) => {
    return (
        <>
            <Layout id="dashboardLayout" className={styles.dashboardLayout}>
                <CollapsableMenu></CollapsableMenu>
                <Col style={{ width: "calc(100% - 80px)" }}>
                    <Row className={styles.dashboardRow}>
                        <Col>
                            <h1 className={styles.heading}>{heading}</h1>
                        </Col>
                        <Col>
                            {buttons?.length > 1 ? (
                                buttons.map((button) => <div>{button}</div>)
                            ) : (
                                <div>{buttons}</div>
                            )}
                        </Col>
                    </Row>
                    <Row id="offlineBody" className={styles.dashboardBody}>
                        <div style={{ width: "100%", maxHeight: "100%" }}>
                            {body}
                        </div>
                    </Row>
                </Col>
            </Layout>
        </>
    );
};

export default DashboardLayout;
