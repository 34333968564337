import React from "react";
import styles from "../../CaseReport.module.css";
import DateTimeFormatter from "../../../../components/Utils/DateTimeFormatter";

export default function CaseReportTabItem({ tabName, tabValue }) {
    let newTabValue = tabValue;
    let newTabName = tabName;

    // Format the date or time if the tab is "Date" or "Time"
    if (tabName === "Date") {
        // Extract just the date part
        newTabValue = (
            <DateTimeFormatter dateTimeString={tabValue} requiredField="date" />
        );
    } else if (tabName === "Time") {
        // Extract just the time part
        newTabValue = (
            <DateTimeFormatter dateTimeString={tabValue} requiredField="time" />
        );
        newTabName = "Time";
    } else if (tabName === "CaseStatus") {
        newTabName = "Status";
    } else if (tabName === "CaseNumber") {
        newTabName = "Case Number";
    } else if (tabName === "SMS_Data") {
        newTabName = "SMS Content";
    } else if (tabName === "ReportingMedium") {
        newTabName = "Medium";
    }

    return (
        <div className={styles.tabItem}>
            <h4>{newTabName}</h4>
            <p>{newTabValue}</p>
        </div>
    );
}
