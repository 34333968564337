import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { useRecoilState } from "recoil";
import { getCaseDetailsByID } from "../../../Adapters/CaseCRUDCalls";
import { getQCcaseReport } from "../../../Adapters/QCcalls";
import { getCounterFromCaseStatus } from "../../../hooks/getCounterFromCaseStatus";
import { caseReportDataState } from "../../../RecoilState/caseReportDataState";
import { QCCaseReportDataState } from "../../../RecoilState/QCCaseReportDataState";
import { tokenState } from "../../../RecoilState/tokenState";
import { userState } from "../../../RecoilState/userState";
import QCForm from "../FeedbackReport/QCForm";
import AcknowledgementMessage from "../GENERALcomponent/AcknowledgementMessage";
import CaseClosing from "../GENERALcomponent/CaseClosing";
import CaseDetailsGeneral from "../GENERALcomponent/CaseDetailsGeneral";
import CaseReportDetails from "../GENERALcomponent/CaseReportDetails";
import styles from "../CaseReport.module.css";
import { Divider, Spin } from "antd";
const VersionCaseReport = ({ selectedVersion }) => {
    const [token, setToken] = useRecoilState(tokenState);
    const [user, setUser] = useRecoilState(userState);
    const [readMode, setReadMode] = useState(true);
    const [openCompleteAction, setOpenCompleteAction] = useState(false);
    const [loading, setLoading] = useState(false);
    const { state } = useLocation();
    const from = state?.myData;
    const [caseDataState, setCaseDataState] =
        useRecoilState(caseReportDataState);
    const [QCcaseDatastate, setQCcaseDatastate] = useRecoilState(
        QCCaseReportDataState
    );
    // const [closingReportExist, setClosingReportExist] = useState(false);
    const [RAFieldflagValues, setRAFieldflagValues] = useState({
        closeModalFlag: false, //to show close modal for particular template
        closingRemarkMandatory: false, //to set all the fields of "closing remark" for special cases as mandatory and non mandator for RA
        CBRRMandatory: false, //to set "remark"- Comment by RA filed for Posh cases as mandatory and non mandator
    });
    // const closingReportExistsFalse = () => {
    //     setClosingReportExist(false);
    // };
    useEffect(() => {
        const fetchData = async () => {
            if (token && from?.id) {
                const qcCaseReportResponse = await getQCcaseReport(
                    from?.id,
                    selectedVersion
                );
                setQCcaseDatastate(qcCaseReportResponse.data);
            }
        };

        fetchData();
    }, [token, selectedVersion]);
    useEffect(() => {
        setLoading(true);
        if (token && from?.id && setCaseDataState) {
            getCaseDetailsByID(from.id, selectedVersion)
                .then((res) => {
                    setCaseDataState(res.data);
                    setLoading(false);
                })
                .catch((err) => {
                    setLoading(false);
                });
        }
    }, [selectedVersion]);
    const [translatedDataObj, setTranslatedDataObj] = useState({});
    function showAckmsgComp(role) {
        const counter = getCounterFromCaseStatus(caseDataState.CaseStatus);

        switch (role) {
            case "CT":
                return true;
            case "CR":
            case "CM":
            case "REGIONAL_ADMIN":
            case "SUPER_ADMIN":
                return (
                    counter > 3 &&
                    QCcaseDatastate?.ackmsg &&
                    Object.keys(QCcaseDatastate?.ackmsg).length !== 0
                );

            default:
                return true;
        }
    }
    return loading ? (
        <Spin></Spin>
    ) : (
        <div style={{ marginTop: "1rem" }}>
            {/* <CaseDetailsGeneral caseData={caseDataState} /> */}
            {/* <Divider className={styles.caseRepDivider} /> */}
            <CaseReportDetails
                showWorkerLanguage={false}
                caseDatastate={caseDataState}
                setcaseDatastate={setCaseDataState}
            />
            <Divider className={styles.caseRepDivider} />
            {showAckmsgComp(user.role) &&
                caseDataState?.Complainer !== null && (
                    <div>
                        <AcknowledgementMessage
                            QCcaseDatastate={QCcaseDatastate?.ackmsg}
                        />
                    </div>
                )}
            <CaseClosing
                caseData={caseDataState}
                setRAFieldflagValues={setRAFieldflagValues}
                translatedDataObj={translatedDataObj}
                setTranslatedDataObj={setTranslatedDataObj}
                disableResolvingReport={false}
                isCTResolveCase={false}
                selectedVersion={selectedVersion}
                // closingReportExistsFalse={closingReportExistsFalse}
            />

            {QCcaseDatastate?.qcReport && (
                <>
                    <h2>
                        QC{" "}
                        {QCcaseDatastate?.qcReport?.qcreport_version < 10
                            ? `0${QCcaseDatastate?.qcReport?.qcreport_version}`
                            : QCcaseDatastate?.qcReport?.qcreport_version}{" "}
                        Report
                    </h2>
                    <QCForm
                        readOnly={readMode}
                        status={QCcaseDatastate?.qcReport?.reportType}
                        openCompleteAction={openCompleteAction}
                        setOpenCompleteAction={setOpenCompleteAction}
                        QCcaseData={QCcaseDatastate?.qcReport}
                    />
                </>
            )}
        </div>
    );
};

export default VersionCaseReport;
