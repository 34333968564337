import React, { useState, useEffect, useRef } from "react";
import {
    Modal,
    Button,
    Spin,
    Typography,
    Row,
    Col,
    Rate,
    Input,
    Form,
    message,
    Divider,
    Popover,
} from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import { useRecoilState } from "recoil";
import {
    fetchTranscriptionData,
    submitFeedback,
} from "../../Adapters/TranscriptionCalls.js";
import styles from "./TranscriptionModal.css";
import { userState } from "../../RecoilState/userState";

const { Text, Title } = Typography;
const { TextArea } = Input;

const TranscriptionModal = ({ audioUrl, isOpen, onClose, caseId }) => {
    const [transcriptionData, setTranscriptionData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [showTranslation, setShowTranslation] = useState(false);
    const [user, setUser] = useRecoilState(userState);
    const [ratingSubmission] = Form.useForm();

    const fetchData = () => {
        setLoading(true);
        fetchTranscriptionData(audioUrl, caseId)
            .then((response) => {
                setTranscriptionData(response?.data);
                if (response?.data?.user_review[user?.roleId]) {
                    ratingSubmission.setFieldsValue({
                        rating: response?.data?.user_review[user?.roleId]
                            ?.rating,
                        comments:
                            response?.data?.user_review[user?.roleId]?.comments,
                    });
                }
                setLoading(false);
            })
            .catch((error) => {
                console.error("Error fetching transcription:", error);
                setLoading(false);
            });
    };

    useEffect(() => {
        if (isOpen) {
            fetchData();
        }
    }, [audioUrl, isOpen]);

    const handleSubmitFeedback = (values) => {
        submitFeedback(transcriptionData.id, values.rating, values.comments)
            .then(() => {
                message.success("Feedback submitted successfully!");
                fetchData(); // Fetch the data again after submitting feedback
            })
            .catch((error) => {
                console.error("Error submitting feedback:", error);
                message.error("Failed to submit feedback.");
            });
    };

    const userHasSubmitted = transcriptionData?.user_review?.[user?.roleId];

    return (
        <Modal
            title="Audio Transcription & Translation"
            open={isOpen}
            onCancel={onClose}
            footer={
                transcriptionData &&
                !userHasSubmitted && [
                    <button
                        className="secondaryButton"
                        type="default"
                        onClick={onClose}>
                        Cancel
                    </button>,
                    <button
                        className="primaryButton"
                        type="primary"
                        onClick={() => ratingSubmission.submit()}>
                        Confirm
                    </button>,
                ]
            }
            width={transcriptionData ? 1200 : 600}
            centered>
            {loading ? (
                <Spin>
                    <div>Loading transcription...</div>
                </Spin>
            ) : transcriptionData ? (
                <Form
                    form={ratingSubmission}
                    layout="horizontal"
                    onFinish={handleSubmitFeedback}
                    className={styles.form}>
                    <Row
                        gutter={[16, 16]}
                        align="middle"
                        style={{ marginBottom: "16px" }}>
                        <Col span={6}>
                            <Title level={5}>Detected Language(s):</Title>
                        </Col>
                        <Col span={18}>
                            <div
                                style={{
                                    borderBottom: "0.5px solid #000",
                                    display: "inline-block",
                                    paddingBottom: "2px",
                                    width: "30%",
                                }}>
                                <Text>
                                    {transcriptionData.detected_language}
                                </Text>
                            </div>
                        </Col>
                    </Row>

                    {/* Audio Transcription */}
                    <Row
                        gutter={[16, 16]}
                        align="middle"
                        style={{ marginBottom: "16px" }}>
                        <Col span={6}>
                            <Title level={5}>
                                Audio Transcription:
                                <Popover
                                    content="This is an AI-generated text, and it may be prone to errors; discretion is advised."
                                    title="Info">
                                    <InfoCircleOutlined
                                        className={styles.infoIcon}
                                    />
                                </Popover>
                            </Title>
                        </Col>
                        <Col span={18}>
                            <TextArea
                                value={transcriptionData.transcription}
                                rows={4}
                                variant="outlined"
                                readOnly
                            />
                        </Col>
                    </Row>

                    {/* Translation */}
                    <Row
                        gutter={[16, 16]}
                        align="middle"
                        style={{ marginBottom: "16px" }}>
                        <Col span={6}>
                            <Button
                                className="secondaryButton"
                                onClick={() => setShowTranslation(true)}>
                                Translate
                            </Button>
                        </Col>
                        <Col span={18}>
                            {showTranslation && (
                                <TextArea
                                    value={transcriptionData.translation}
                                    readOnly
                                    autoSize={{ minRows: 3 }}
                                    variant="outlined"
                                    className={styles.translationTextArea}
                                />
                            )}
                        </Col>
                    </Row>

                    <Divider className="caseRepDivider" />

                    {/* Feedback Form */}
                    <Row gutter={[16, 16]}>
                        <Col span={24}>
                            <Title level={5} style={{ textAlign: "left" }}>
                                Your feedback is valuable! Please help us make
                                this feature better for you.
                            </Title>
                        </Col>
                        {userHasSubmitted ? (
                            <>
                                <Col span={12}>
                                    <Form.Item
                                        name="rating"
                                        label="Your Rating"
                                        style={{ textAlign: "left" }}>
                                        <Rate
                                            value={
                                                transcriptionData.user_review[
                                                    user.roleId
                                                ].rating
                                            }
                                            disabled
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        name="comments"
                                        label="Your Comments"
                                        style={{ textAlign: "left" }}>
                                        <TextArea
                                            value={
                                                transcriptionData.user_review[
                                                    user.roleId
                                                ].comments
                                            }
                                            readOnly
                                            rows={4}
                                        />
                                    </Form.Item>
                                </Col>
                            </>
                        ) : (
                            <>
                                <Col span={12}>
                                    <Form.Item
                                        name="rating"
                                        label="Rate the accuracy of the transcribed text compared to the original voice note."
                                        rules={[
                                            {
                                                required: true,
                                                message:
                                                    "Please provide a rating.",
                                            },
                                        ]}>
                                        <Rate className="large-rate" />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        name="comments"
                                        label="Comments"
                                        rules={[
                                            {
                                                required: true,
                                                message:
                                                    "Please provide comments.",
                                            },
                                        ]}
                                        style={{ textAlign: "left" }}>
                                        <TextArea
                                            rows={4}
                                            placeholder="Add your feedback here"
                                        />
                                    </Form.Item>
                                </Col>
                            </>
                        )}
                        <Divider className="caseRepDivider" />
                    </Row>
                </Form>
            ) : (
                <Text style={{ textAlign: "left" }}>
                    No transcription data available.
                </Text>
            )}
        </Modal>
    );
};

export default TranscriptionModal;
